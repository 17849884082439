import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type UserService from 're-client/services/user'

export default class CrittersIndexRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare user: UserService

  override async model() {
    const numberOfLessonsCompleted =
      this.studentProgress.lessonsCurrentLessonNumber - 1

    const variables = {
      student: this.user.student,
      lesson_number: numberOfLessonsCompleted,
      maxMap: this.studentProgress.lessonsLastMap,
    }

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'my_critters'),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
