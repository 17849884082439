import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type UserService from 're-client/services/user'

export default class ActivitySidebarService extends Service {
  @service
  declare user: UserService

  @tracked isOpen = false

  /**
   * Points to award to the user.
   *
   * Update this property to play the flying eggs animation.
   */
  @tracked pointsToAward: number[] = []

  @tracked flyingEggs = true

  close() {
    this.isOpen = false
  }

  /**
   * Open the activity sidebar, optionally awarding points to the user.
   *
   * @param points Number of points to award to the user.
   */
  open(points?: number) {
    this.isOpen = true

    if (typeof points === 'number') {
      this.pointsToAward = distributePoints(points)
    }
  }

  toggle() {
    this.isOpen = !this.isOpen
  }

  /**
   * Increments the user's points by the next value in the `pointsToAward` array.
   */
  incrementPoints() {
    const points = this.pointsToAward.pop()

    if (typeof points === 'number') {
      this.user.incrementEggs(points)
    }
  }
}

/**
 * Evenly distribute points across the number of eggs to animate.
 *
 * Used by the flying eggs animation to show number of points awarded.
 *
 * @param points number of points to distribute
 * @returns an array of points to award
 */
function distributePoints(points: number, maxPoints = 10) {
  // Limit the animation to 10 eggs, or the number of points, whichever is smaller.
  const steps = Math.min(points, maxPoints)
  const pointsPerStep = Math.floor(points / steps)
  const pointsRemainder = points % steps

  const pointsToAward = Array<number>(steps)

  if (pointsRemainder > 0) {
    // Insert the remainder as the first step
    pointsToAward.splice(0, 1, pointsPerStep + pointsRemainder)
    return pointsToAward.fill(pointsPerStep, 1, steps)
  }

  return pointsToAward.fill(pointsPerStep)
}

declare module '@ember/service' {
  interface Registry {
    'activity-sidebar': ActivitySidebarService
  }
}
