import Service, { service } from '@ember/service'
import { graphql } from 're-client/graphql'
import type { FeatureService } from '@blakeelearning/features'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/transition'
import { isEqual } from 'lodash'
import { useQuery } from 're-client/resources/query'
import transitionToArray from 're-client/utils/transition-to-array'
import type StudentProgressService from './student-progress'

graphql(/* GraphQL */ `
  fragment QuestGoalEssentialDataFragment on Student {
    id
    essentialQuestGoal {
      ... on QuestGoalLesson {
        rewardAmount
        id
        lesson
        progressCurrent
        progressTotal
      }
      ... on QuestGoalMap {
        rewardAmount
        id
        map
      }
      __typename
    }
  }
`)

const StudentEssentialQuestQueryDocument = graphql(/* GraphQL */ `
  query GetStudentEssentialQuest {
    student {
      ...QuestGoalEssentialDataFragment
    }
  }
`)

export default class EssentialQuestService extends Service {
  @service declare features: FeatureService

  @service declare router: RouterService

  @service declare studentProgress: StudentProgressService

  _studentEssentialQuestQuery = useQuery(this, () => {
    return {
      query: StudentEssentialQuestQueryDocument,
    }
  })

  get active() {
    return Boolean(this.goal)
  }

  get goal() {
    return this._studentEssentialQuestQuery.current.data?.student
      ?.essentialQuestGoal
  }

  get hasLessonsPlacementTest() {
    return this.studentProgress.showLessonsPlacementTest
  }

  get targetRouteParams() {
    if (!this.goal) return
    if (this.hasLessonsPlacementTest) return ['lessons.placement-test']

    switch (this.goal.__typename) {
      case 'QuestGoalLesson':
        return [
          'lessons.lesson.activity',
          this.goal.lesson.toString(),
          (this.goal.progressCurrent + 1).toString(),
        ]
      case 'QuestGoalMap':
        return ['lessons.map.quiz', this.goal.map.toString()]
      default:
        return undefined
    }
  }

  get title() {
    if (!this.goal) return
    if (this.hasLessonsPlacementTest) return 'Placement Test'

    switch (this.goal.__typename) {
      case 'QuestGoalLesson':
        return `Lesson ${this.goal.lesson.toString()}`
      case 'QuestGoalMap':
        return `Map ${this.goal.map.toString()}`
      default:
        return undefined
    }
  }

  get instruction() {
    if (!this.goal) return
    if (this.hasLessonsPlacementTest)
      return 'Before starting your Essential Quest you must complete the Placement Test.'
    switch (this.goal.__typename) {
      case 'QuestGoalLesson':
        return `Complete all the activities in lesson ${this.goal.lesson.toString()}. You have ${(this.goal.progressTotal - this.goal.progressCurrent).toString()} left to go!`
      case 'QuestGoalMap':
        return `Complete the Map ${this.goal.map.toString()} Quiz.`
      default:
        return undefined
    }
  }

  get reward() {
    if (!this.goal) return
    return this.goal.rewardAmount
  }

  async setup() {
    await this._studentEssentialQuestQuery.current.result()
  }

  async fetch() {
    await this._studentEssentialQuestQuery.current.refetch()
    await this.setup()
  }

  checkTransition(transition: Transition) {
    if (!this.goal) return
    if (transition.targetName === 'index') return
    if (isEqual(transitionToArray(transition), this.targetRouteParams)) return

    void this.router.replaceWith('index')
  }
}

declare module '@ember/service' {
  interface Registry {
    'essential-quest': EssentialQuestService
  }
}
